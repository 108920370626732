// import flier from "./assets/ican-flyer-2022.jpg";
// import TREASURER from "./assets/TREASURER UGWU STEPHEN ONYEMACHI FCA.jpg";
// import SECRETARY from "./assets/SECRETARY EKE MICHAEL OMOGOR ACA.jpg";
// import PASTSECRETARY from "./assets/IMMEDIATE PAST SECRETARY OVAGA JOSEPHINE UCHENNA FCA.jpg";
// import CHAIRMAN from "./assets/CHAIRMAN     ELVIS EMEKA OZOADIBE ACA.jpg";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  landingPage: {
    banner: [
      {
        venue: "The Conference Will Hold Virtually and Physically",
        theme:
          "BEYOND NIGERIA'S ECONOMIC CRISIS: Unlocking Opportunities for growth",
        year: "19TH EASTERN ZONAL ACCOUNTANTS' CONFERENCE.",
        dateNTime: "Tuesday 1st - Friday 4th April, 2025",
        imageURL:
          "https://i.ibb.co/MCsvZ3F/267747493-4687870767962215-8153782044804205123-n.jpg",
      },
      {
        venue: "The Conference Will Hold Virtually and Physically",
        theme:
          "BEYOND NIGERIA'S ECONOMIC CRISIS: Unlocking Opportunities for growth",
        year: "19TH EASTERN ZONAL ACCOUNTANTS' CONFERENCE.",
        dateNTime: "Tuesday 1st - Friday 4th April, 2025",
        imageURL:
          "https://i.ibb.co/MCsvZ3F/267747493-4687870767962215-8153782044804205123-n.jpg",
      },
      {
        venue: "The Conference Will Hold Virtually and Physically",
        theme:
          "BEYOND NIGERIA'S ECONOMIC CRISIS: Unlocking Opportunities for growth",
        year: "19TH EASTERN ZONAL ACCOUNTANTS' CONFERENCE.",
        dateNTime: "Tuesday 1st - Friday 4th April, 2025",
        imageURL:
          "https://i.ibb.co/MCsvZ3F/267747493-4687870767962215-8153782044804205123-n.jpg",
      },
      {
        venue: "The Conference Will Hold Virtually and Physically",
        theme:
          "BEYOND NIGERIA'S ECONOMIC CRISIS: Unlocking Opportunities for growth",
        year: "19TH EASTERN ZONAL ACCOUNTANTS' CONFERENCE.",
        dateNTime: "Tuesday 1st - Friday 4th April, 2025",
        imageURL:
          "https://i.ibb.co/MCsvZ3F/267747493-4687870767962215-8153782044804205123-n.jpg",
      },
      {
        venue: "The Conference Will Hold Virtually and Physically",
        theme:
          "BEYOND NIGERIA'S ECONOMIC CRISIS: Unlocking Opportunities for growth",
        year: "19TH EASTERN ZONAL ACCOUNTANTS' CONFERENCE.",
        dateNTime: "Tuesday 1st - Friday 4th April, 2025",
        imageURL:
          "https://i.ibb.co/MCsvZ3F/267747493-4687870767962215-8153782044804205123-n.jpg",
      },
    ],
    about: {
      // flier: flier,
      year: "Current Year",
      theme:
        "BEYOND NIGERIA'S ECONOMIC CRISIS: Unlocking Opportunities for growth",
      details: [
        {
          detail:
            "It is our pleasure to welcome you to the 19th Eastern Zonal Districts Conference of the Institute of Chartered Accountants of Nigeria (ICAN), holding virtually and physically from Tuesday 1st - Friday 4th April, 2025. "
        },
        {
          detail:
            "The Institute of Chartered Accountants of Nigeria (ICAN) is Nigeria’s foremost professional Accountancy body established by Act of Parliament No. 15 of 1965. The Eastern Zonal District of the Institute of Chartered Accountants of Nigeria (ICAN) is made up of District Societies in the 5 states of the South East geopolitical zone of Nigeria. The Zonal District holds her Zonal Conference every year, where national topical and economic issues are discussed. The Annual Eastern Zonal Conference have been successfully held in the following states within the zone; Owerri (2007), Enugu (2010 and 2018), Umuahia (2011), Awka (2014), Abakaliki (2016), Onitsha (2019), Aba (2021), Owerri (2022), Awka (2023) and Abakaliki (2024).",
        },
        {
          detail:
            "The 2025 (19th) edition of Eastern Zonal Accountants’ Conference is scheduled to hold at the Ochendo International Conference Centre (ICC), Umuahia, Abia State from April 1st to 4th, 2025 with the Theme: Beyond Nigeria’s Economic Crisis: Unlocking Opportunities For Growth. The sub-themes and papers would be presented by eminent and subject matter experts, with practicable solutions crafted within.",
        },
        {
          detail: "The Conference will be attended by Managing Directors/ CEOs, Executive Directors, Finance Managers/ Officers, Captains of Industries, top Government functionaries etc. "
        },
        {
          detail:
            "This year’s conference will be held both virtually and physically. Virtual participants are expected to pay ₦30,000 which covers their certificate of attendance, softcopy of papers presented at the conference and they will also be entitled to 16 MCPE hours (for members of the Institute of Chartered Accountants of Nigeria), Young Accountants (>MB052000, & <30yrs) are to pay ₦35,000   while other members of the Institute attending the conference physically will pay ₦50,000 and non-members of the Institute attending physically will pay a flat rate of ₦60,000 which entitles them to certificate of attendance and softcopy of papers presented at the conference and other materials.",
        },
        {
          detail:
            "We therefore respectfully request you to register and participate fully in the forthcoming Eastern Zonal Districts Conference that will enable you obtain the following benefits; E-conference certificate, E-conference brochure/programme proceedings, softcopy of conference papers, softcopy of payment receipt, constant SMS and email updates on issues relating to the conference and many more.",
        },
        {
          detail:
            "Consequently, we wish to also encourage you to sponsor and brand choice conference items/materials with your logo/ emblem, and/ or place adverts or goodwill messages in our colourful Conference brochure (about 500 copies to be produced) and website (www.ican.ezdconference.org.ng), to showcase and deepen the market share for your products and services.  This will enable you directly reach over Thirty Thousand (30000) Chartered Accountants and other participants that are anticipated to view the zone’s conference website or download the conference Application.",
        },
      ],
    },

    plenary: [
      {
        session: "PLENARY ONE",
        title: "Leveraging Digital Technologies for Food Security in Nigeria",
        chairman: " Mazi Nnamdi Okwuadigbo FCA, (PP)",
        presenter: "Prof M. O. Iwe",
        discussant: "Chief Oye Akinsulire FCA",
        profilePresentation: "Dr. Ndukwe Dibia FCA",
        note: "Technology drives the world, and the impact of technology on the Agricultural sector cannot be overemphasized. The world is at the cusp of the biggest economic transformation of any kind in this sector, as traditional farming is being replaced by brewing microbes to produce food. The arguments about plant-versus meat-based diets, are being made irrelevant by new technologies as most of our food will now be coming neither from animals nor plants, but from unicellular life. In view of the disconnect between population growth and food supply rate, this paper will be focusing on how these emerging technologies can be leveraged to achieve food security in Nigeria.",
      },
      {
        session: "PLENARY TWO",
        title: "Natural Capital Accounting and Valuation of Nations wealth",
        chairman: " Mr Chidi Ajaegbu (PP)",
        presenter: " Dr. Nnaemeka Chukwuone",
        discussant: "Dr. Innocent Okwuosa FCA, and Prof Emeka Marius",
        profilePresentation: "Dr. Elvis Ozoadibe FCA",
        note: "Nations evaluate their economic performances and assess the effectiveness of their development policies and plans by keeping a close watch on their national income accounts; but the traditional indicators like GDP (gross domestic product)which are based on national income accounts say nothing about the longer term sustainability of the nation’s growth patterns. It is therefore in the interest of nations to move beyond traditional GDP and start incorporating their natural capital into their national accounts to make better economic decisions. This paper will offer natural capital accounting implementation strategies for a more sustainable world economic analysis and decisions",
      },
      {
        session: "PLENARY THREE",
        title:
          "African Continental Free Trade agreement: Opportunities for the Agricultural Sector",
        chairman: " Chief R.U Uche (PP)",
        presenter: " Dr. Mrs Ngozi Ogwo FCA",
        discussant: "Dr. Etofolam Felix Osuji FCA",
        profilePresentation: "Mr Emmanuel Nnamani Ekene FCA",
        note: "The alliance aimed at speeding up international trade, unlocking investment and achieving economic growth among African countries and beyond remained unattainable until African Continental Free Trade agreement was signed. Africa needed the agreement to integrate further into global supply chains. Africa's new free trade area is therefore a global game changer. Short-termism, polarization and isolationism as political tools have been the bane of African Continental trade over the years. This paper will discuss the challenges and the opportunities in diversifying exports and enhancing trade integrity for national economic growth. It will also identify the opportunities for professionals in the African Continental Free Trade agreement.",
      },
      {
        session: "HEALTH TALK",
        title: "Longevity and Stress Management ",
        chairman: "Chief D.C. S Alaribe FCA",
        presenter: "Dr. Barthy Okorochukwu",
        discussant: "Dr. Etofolam Felix Osuji FCA",
        profilePresentation: "Dr. Leonard Uguru FCA",
        note: "Recent cases of sudden deaths among our members calls for serious concern. Research has shown that there is a relationship between life style and longevity. In this session, experts will lead discussions on stress management and how lifestyle choices could impact longevity.",
      },
    ],
    ads: [
      {
        url: "assets/images/ads/ads (1).jpg",
      },
      {
        url: "assets/images/ads/ads (2).jpg",
      },
      {
        url: "assets/images/ads/ads (3).jpg",
      },
      {
        url: "assets/images/ads/ads (4).jpg",
      },
      {
        url: "assets/images/ads/ads (5).jpg",
      },
      {
        url: "assets/images/ads/ads (6).jpg",
      },
      {
        url: "assets/images/ads/ads (7).jpg",
      },
      {
        url: "assets/images/ads/ads (8).jpg",
      },
      {
        url: "assets/images/ads/ads (9).jpg",
      },
      {
        url: "assets/images/ads/ads (10).jpg",
      },
      {
        url: "assets/images/ads/ads (11).jpg",
      },
      {
        url: "assets/images/ads/ads (12).jpg",
      },
      {
        url: "assets/images/ads/ads (13).jpg",
      },
      {
        url: "assets/images/ads/ads (14).jpg",
      },
      {
        url: "assets/images/ads/ads (15).jpg",
      },
      {
        url: "assets/images/ads/ads (16).jpg",
      },

      {
        url: "assets/images/ads/ads (1).png",
      },
      {
        url: "assets/images/ads/ads (2).png",
      },
      {
        url: "assets/images/ads/ads (3).png",
      },
      {
        url: "assets/images/ads/ads (4).png",
      },
      {
        url: "assets/images/ads/ads (5).png",
      },
      {
        url: "assets/images/ads/ads (6).png",
      },
      {
        url: "assets/images/ads/ads (7).png",
      },
      {
        url: "assets/images/ads/ads (8).png",
      },
      {
        url: "assets/images/ads/ads (9).png",
      },
      {
        url: "assets/images/ads/ads (10).png",
      },
      {
        url: "assets/images/ads/ads (11).png",
      },
      {
        url: "assets/images/ads/ads (12).png",
      },
      {
        url: "assets/images/ads/ads (13).png",
      },
      {
        url: "assets/images/ads/ads (14).png",
      },
      {
        url: "assets/images/ads/ads (15).png",
      },
      {
        url: "assets/images/ads/ads (16).png",
      },
      {
        url: "assets/images/ads/ads (17).png",
      },
      {
        url: "assets/images/ads/ads (18).png",
      },
      {
        url: "assets/images/ads/ads (19).png",
      },
      {
        url: "assets/images/ads/ads (20).png",
      },
      {
        url: "assets/images/ads/ads (21).png",
      },
      {
        url: "assets/images/ads/ads (22).png",
      },
      {
        url: "assets/images/ads/ads (23).png",
      },
      {
        url: "assets/images/ads/ads (24).png",
      },
      {
        url: "assets/images/ads/ads (25).png",
      },
      {
        url: "assets/images/ads/ads (26).png",
      },
    ],

    speakers: {
      theme:
        "REVENUE BEYOND OIL: LEVERAGING NON-OIL EXPORT FOR NIGERIA'S ECONOMIC SURVIVAL",
      speakers: [

        {
          name: "LADY ADA CHUKWUDOZIE, NPOM, MNSE, FCSN, FIOD",
          position: "Chairman, Board of Directors KeyStone Bank Ltd",
          imageURL: "assets/images/speakers/ada-chukwudozie.jpg",
          details: [
            {
              detail:
                "Ngozi Angela Ogwo - PhD, is the Managing Partner/CEO of Grant Thornton Nigeria and a member of the Board of Governors of Grant Thornton International. She is also a member of the Innovation and Technology Committee as well as the Enterprise Risk Management Committee of Grant Thornton International. Ngozi has over 35 years experience delivering distinctive advisory, audit & assurance, tax and banking services to a wide range of clients in Nigeria and overseas. Ngozi is a dynamic, focused, and experienced professional who is poised to assist clients reach their goals, define new ones, and unlock their potentials for growth. Ngozi Ogwo holds an MBA degree in banking and finance (Nigeria) and a Doctor of Philosophy (PhD) degree in finance (USA). She is a fellow of both the Institute of Chartered Accountants of Nigeria (ICAN) and the Chartered Institute of Taxation of Nigeria (CITN). She is also a fellow of the Institute of Management Consultants of Nigeria (IMC). She is a licensed capital market consultant (Nigeria) and a certified member of the International Due Diligence Organization (IDDO). She is also a Certified Forensic Accountant of Nigeria (CFAN). Ngozi is a certified Coach by the European Mentoring & Coaching Council (EMCC) and is an Alumnus of the Saïd Business School, University of Oxford, UK. She has served in various committees of the accounting institute ICAN, including the Forum of Firms Executive Committee, the Professional Practice Monitoring Committee and the Strategy, Advocacy and Performance Tracking committee. She is also involved in social and volunteer services especially to women in the church and community. Ngozi is a renowned paper presenter and conference speaker both locally and internationally.",
            },
          ],
          sessions: [
            {
              day: "Day 1",
              from: "10:30",
              to: "11:30",
              period: "AM",
              topic: "Marketing Matters",
            },
          ],
        },

        {
          name: "PROF. OTI WILBERFORCE OTU JOHN",
          position: "Professor of Industrial and Analytical Chemistry,Ebonyi State University, Abakaliki, Ebonyi State Nigeria.",
          imageURL: "assets/images/speakers/wilberforce-oti.png",
          details: [
            {
              detail:
                "Prof. Babatunde Bayodele Olofin is the Director of the Information and Communication Technology Centre (ICTC) at Enugu State University of Science and Technology (ESUT). He is responsible for developing the University's technology strategy and providing leadership and direction concerning the operation of ESUT's technology systems, core business applications, voice and data networks, IT Security, data centre operations, and the implementation of the University's enterprise resource planning project.",
            },
          ],
          sessions: [
            {
              day: "Day 1",
              from: "10:30",
              to: "11:30",
              period: "AM",
              topic: "Marketing Matters",
            },
          ],
        },
        {
          name: "Dr Vincent Nwani, Phd, Msc",
          position:
            "Director, Strategy and Business Intelligence, Safrik Investments Group",
          imageURL: "assets/images/speakers/vincent-nwani.jpg",
          details: [
            {
              detail:
                "Dr. Uchechukwu S. Ogah is a seasoned Accountant, Oil & Gas expert, consummate industrialist, finance guru, and philanthropist. He is the founder and former President of Masters Energy Group, a conglomerate comprising 15 subsidiaries spanning oil and gas, transport, finance, marine, logistics, and aviation. With over a decade-long career at Zenith Bank Group, he attained the position of Assistant-General Manager and garnered a reputation for achieving corporate growth. Dr. Ogah ventured into politics and secured the Abia Gubernatorial candidacy of the APC, finishing second in the 2019 polls.",
            },
          ],
          sessions: [
            {
              day: "Day 1",
              from: "10:30",
              to: "11:30",
              period: "AM",
              topic: "Marketing Matters",
            },
          ],
        },
        {
          name: "PROFESSOR OGBONNA GABRIEL NKWAZEMA’S",
          position:
            "Professor of Accounting and Information Technology",
          imageURL: "assets/images/speakers/gabriel-nkwazema.jpg",
          details: [
            {
              detail:
                "Prof. Wilson Uchenna Ani holds a Ph.D. in Banking and Finance from the University of Nigeria. He also holds a B.Sc. degree in Accountancy and two Masters degrees in Accountancy and Finance from the University of Nigeria and Nnamdi Azikiwe University, Awka respectively. He is a Fellow (FCA) of the Institute of Chartered Accountants of Nigeria (ICAN) and Chartered Institute of Taxation, Nigeria (CITN). Currently, he serves as a Professor of Banking and Finance and the Dean of the College of Management Sciences at Michael Okpara University of Agriculture, Umudike, Abia State. With a wealth of experience in academia, Prof. Ani has held various leadership positions including Head of Department of Banking and Finance at Michael Okpara University of Agriculture and Dean/Director of the School of Financial Studies at IMT, Enugu. He has authored over sixty academic articles published in reputable journals worldwide and has authored fifteen academic textbooks. Additionally, he is a visiting Professor/lecturer at various institutions and serves as an external examiner for several universities. His research interests include frontier finance issues, risk mitigation using derivatives instruments, and the rising roles of Artificial Intelligence in the financial sector. Prof. Ani is married with children and is committed to his Christian faith.",
            },
          ],
          sessions: [
            {
              day: "",
              from: "",
              to: "",
              period: "",
              topic: "",
            },
          ],
        },
      ],
    },
    schedules: {
      days: [
        {
          day: "Day 01",
          date: "Tuesday",
          schedule: [
            {
              time: "8:30am – 5:30pm",
              session: "Arrival",
              description: "Registration and Collection of Conference Materials",
              imageURL: null
            },
            {
              time: "8:30am – 10:30am",
              session: "Arrival",
              description: "Receiving the 59th ICAN President at Akanu Ibiam International Airport by Abakiliki & District Society",
              imageURL: null
            },
            {
              time: "10:30am – 12:15pm",
              session: "Arrival",
              description: "Courtesy Call/Visit to the Palace of HRM Eze Sunday Oketa (Onu Oha 111)",
              imageURL: null
            },
            {
              time: "12:15pm – 2:30pm",
              session: "Arrival",
              description: "Visit to the Vice Chancellor of Alex Ekwueme Federal University, Ndufualike",
              imageURL: null
            },
            {
              time: "2:30pm – 4:00pm",
              session: "Arrival",
              description: "Courtesy Call/Visit to the Executive Governor of Ebonyi State, Right Hon. Builder Francis Ogbonnaya Nwifuru.",
              imageURL: null
            },
            {
              time: "4:00pm – 5:00pm",
              session: "Presentation",
              description: "Radio Programme with the 59th ICAN President at Legacy FM",
              imageURL: null
            },
            {
              time: "7:00pm – 10:00pm",
              session: "Cultural Night",
              description: "Pre-Conference Cultural Night at Osborne Lapalm Hotel (Hosted by Abakiliki & District Society)",
              imageURL: null
            }
          ]
        },
        {
          day: "Day 02",
          date: "Wednesday",
          schedule: [
            {
              time: "6:00am – 7:00am",
              session: "Keep Fit Activities",
              description: null,
              imageURL: null
            },
            {
              time: "8:00am – 5:30pm",
              session: "Registration and Accreditation of Conference Participants",
              description: null,
              imageURL: null
            },
            {
              time: "8:30am – 9:30am",
              session: "Plenary 1",
              description: "Plenary 1: Artificial Intelligence: Opportunities and Implications on the future of Chartered Accountants by Prof W.U Ani (Physical Presentation) and Prof. Babatunde Bayodele Olofin (Virtual Presentation)",
              imageURL: null
            },
            {
              time: "9:30am - 10:30am",
              session: "Discussion",
              description: "Technical /Discussion session: Hon. Dr. L. Uguru FCA the Honorable Commissioner for Finance and Economic Development, Ebonyi State, Prof W.U Ani FCA and Dr. Magnus Nnaemeka Chukwuekezie FCA",
              imageURL: null
            },
            {
              time: "10:00am – 10:30am",
              session: "Break",
              description: "Lunch Break",
              imageURL: null
            },
            {
              time: "10:30am – 1:00pm",
              session: "Opening Formalities: National Anthem, Ebonyi State Anthem and ICAN Anthem Welcome Address by the Eastern Zonal Chairman Keynote Address by the 59th President of ICAN An Address by the Executive Governor of Ebonyi State, Governor Right Hon. Builder Francis Ogbonnaya Nwifuru. Vote of Thanks Group Photographs",
              description: null,
              imageURL: null
            },
            {
              time: "1:00pm – 2:00pm",
              session: "Break",
              description: "Lunch Break",
              imageURL: null
            },
            {
              time: "2:00pm – 3:00pm",
              session: "Plenary 2",
              description: "Plenary 2: Unlocking Nigeria’s Economic Potentials through the adoption of Artificial Intelligence and Robotics in governance by Dr. Uche Ogah FCA (Physical Presentation)",
              imageURL: null
            },
            {
              time: "3:00pm – 3:45pm",
              session: "Discussion",
              description: "Technical /Discussion session",
              imageURL: null
            },
            {
              time: "3:45pm – 4:00pm",
              session: "Announcement",
              description: "Announcement/Closing Remarks for the Day",
              imageURL: null
            }
          ]
        },
        {
          day: "Day 03",
          date: "Thursday",
          schedule: [
            {
              time: "6:00am – 7:00am",
              session: "Keep Fit Activities",
              description: null,
              imageURL: null
            },
            {
              time: "9:00am – 10:00am",
              session: "Plenary 3",
              description: "Plenary 3: Innovations in Robotics and Artificial Intelligence: Building a Cognitive Enterprise by Dr. Ngozi Ogwo FCA (Physical Presentation)",
              imageURL: null
            },
            {
              time: "10:00am – 11:00am",
              session: "Discussion",
              description: "Technical /Discussion session",
              imageURL: null
            },
            {
              time: "12:30pm – 1:30pm",
              session: "Health Talk",
              description: "Health Talk by Dr. Collins Ugwu",
              imageURL: null
            },
            {
              time: "1:30pm – 2:30pm",
              session: "Report",
              description: "Rapporteur-General’s Report",
              imageURL: null
            },
            {
              time: "2:30pm – 3:15pm",
              session: "Closing Ceremonies",
              description: null,
              imageURL: null
            },
            {
              time: "7:00pm – 10:00pm",
              session: "Gala Nite",
              description: null,
              imageURL: null
            }
          ]
        },
        {
          day: "Day 04",
          date: "Friday",
          schedule: [
            {
              time: "6:00am – 7:00am",
              session: "Keep Fit Activities",
              description: null,
              imageURL: null
            },
            {
              time: "7:30am – 12:00pm",
              session: "Departure",
              description: null,
              imageURL: null
            }
          ]
        }
      ]
    },

    travelInfo: {
      theme:
        "BEYOND NIGERIA'S ECONOMIC CRISIS: Unlocking Opportunities for growth",
      venue:
        "The 19TH ICAN Easthern Zonal Conference will be holding at the heart of The The Conference Will Now Hold Virtually and Physically",
      transport:
        "Attendees who would not be staying around the conference venue are advised to include a little extra budget for their daily transport.",
      hotel:
        "The conference is organized on a non-residential basis. Affordable accommodations are however available within and around the Conference venue.",
    },
  },
  pastPresident: {
    AAN: [
      {
        name: "Akintola WILLIAMS, CBE, CFR, B.Com., FCA",
        year: "1960 – 61",
      },
      {
        name: "Akintola WILLIAMS, CBE, CFR, B.Com., FCA",
        year: "1961 – 62",
      },
      {
        name: "Frank Cuthbert Oladipo COKER, CFR, B.Com., FCA (Deceased)",
        year: "1962 – 63",
      },
      {
        name: "Frank Cuthbert Oladipo COKER, CFR, B.Com., FCA (Deceased)",
        year: "1963 – 64",
      },
      {
        name: "Frank Cuthbert Oladipo COKER, CFR, B.Com., FCA (Deceased)",
        year: "1964 – 65",
      },
      {
        name: "Frank Cuthbert Oladipo COKER, CFR, B.Com., FCA (Deceased)",
        year: "1964 – 65",
      },
    ],
    ICAN: [
      {
        name: "Frank Cuthbert Oladipo COKER, CFR, B.Com., FCA (Deceased)",
        year: "1965 – 66",
      },
      {
        name: "Ephraim Adekunle OSINDERO, (Otunba) MON, FCA (Deceased)",
        year: "",
      },
      {
        name: "Herbert William BOND, FCA (Deceased)",
        year: "1966 – 67",
      },
      {
        name: "Zacchaeus Oludayo OSOSANYA, FCA (Deceased)",
        year: "1967 – 68",
      },
      {
        name: "Alfred EHREN, FCA",
        year: "1968 – 69",
      },
      {
        name: "Felix Bankole CARDOSO, FCA (Deceased)",
        year: "1969 – 70",
      },
      {
        name: "Emmanuel Ayodele ODUKOYA, (Archdeacon), FCA, (Deceased)",
        year: "1970 – 71",
      },
      {
        name: "Musiliu Olaiya ANIBABA, (Chief), FCA",
        year: "1971 – 72",
      },
      {
        name: "Vincent Obajimi Adebisi OGUNBA, FCA, (Deceased)",
        year: "1973 – 74",
      },
      {
        name: "Julius Akinyokun OWOSENI, (Chief) FCA, (Deceased)",
        year: "1974 – 75",
      },
      {
        name: "Michael Adepoju ADEYEMO, (Prof.), M.Sc., (ECONS), FCA (Deceased)",
        year: "1975 – 76",
      },
      {
        name: "Cecil Oyeniyi Olurotimi OYEDIRAN, B.Sc., (ECONS),MFR, FCA",
        year: "1976 – 77",
      },
      {
        name: "Anthony Asuquo ANI, (Chief) MON, FCA",
        year: "1977 – 78",
      },
      {
        name: "Arthur Christopher Izuegbunam MBANEFO, (Amb. Chief) (Odu III) MFR, CON, FCA",
        year: "1978 – 79",
      },
      {
        name: "Joseph Akintunde Alaba ADEBAYO, (Prince), FCA",
        year: "1979 – 80",
      },
      {
        name: "John Adepoju BALOGUN (Sir), FCA (Deceased)",
        year: "1980 – 81",
      },
      {
        name: "Idris Onaolapo SULAIMON, (Alhaji), FCA",
        year: "1981 – 82",
      },
      {
        name: "Olusola FALEYE, (Chief), FCA",
        year: "1982 – 83",
      },
      {
        name: "Dolanimi Babafemi Olabamidele OGUTUGA, FCA",
        year: "1983 – 84",
      },
      {
        name: "Johnson Kayode Osiyemi OSINAIKE, FCA",
        year: "1984 – 85",
      },
      {
        name: "Otunba Adedoyin Olayide OGUNDE, FCA, (Deceased)",
        year: "1985 – 86",
      },
      {
        name: "Samie Aremu, WILLIAMS, FCA, (Deceased)",
        year: "1986 – 87",
      },
      {
        name: "Johnson Olaobaju Olabisi OMIDIORA, (Balogun) B.Sc., OON, FCA",
        year: "1987 – 88",
      },
      {
        name: "Ebenezer Folorunsho OKE, (Chief) B.Sc., FCA (Deceased)",
        year: "1988 – 89",
      },
      {
        name: "HH. The Otunba Ayora (Dr.) Bola KUFORIJI-OLUBI, B.Sc, FCA, OON, MON, (Deceased)",
        year: "1989 – 90",
      },
      {
        name: "Michael Ayodeji ONI, B.Sc., FCA",
        year: "1990 – 91",
      },
      {
        name: "Cornelius Oladipupo Sunday OSENI, B.Sc., FCA",
        year: "1991 – 92",
      },
      {
        name: "Oluwole Alani ADEOSUN, (Chief) B.Sc. OON, FCA (Deceased)",
        year: "1992 – 93",
      },
      {
        name: "Ismaila USMAN, (Mallam), FCA",
        year: "1993 – 94",
      },
      {
        name: "Olutoyin Olusola OLAKUNRI, (Chief, Mrs.), OFR, FCA",
        year: "1994 – 95",
      },
      {
        name: "Simeon Olusola OGUNTIMEHIN, (Sir) OON, FCA",
        year: "1995 – 96",
      },
      {
        name: "Emmanuel Itoya IJEWERE, FCA",
        year: "1996 – 97",
      },
      {
        name: "Agnes Adenike ADENIRAN (Princess), FCA",
        year: "1997–98",
      },
      {
        name: "Ike NWOKOLO, (Sir), OFR, KSC, FCA",
        year: "1998–99",
      },
      {
        name: "Adeboye Olugboyega, BADEJO (Chief), FCA",
        year: "1999–2000",
      },
      {
        name: "Herbert Adewole AGBEBIYI, FCA",
        year: "2000-2001",
      },
      {
        name: "Ugochukwu Stephen NWANKWO (Chief), FCA",
        year: "2001-2002",
      },
      {
        name: "Felix Kolawole BAJOMO (Chief), mni, FCA",
        year: "2002-2003",
      },
      {
        name: "Jaiye Kofolaran, RANDLE, (Bashorun), FCA",
        year: "2003-2004",
      },
      {
        name: "Ibironke Mojisola Osiyemi (Mrs), FCA",
        year: "2004-2005",
      },
      {
        name: "Abdul Lateef Adebayo Owoyemi (Alhaji, Otunba), FCA",
        year: "2005-2006",
      },
      {
        name: "Catherine Ginikanwa Okpareke, (Chief, Dr., Mrs.), B.A, MBA, D (Lit), MNIM, mni, FCA",
        year: "2006-2007",
      },
      {
        name: "Abiodun Babington-Ashaye, (Prince), FCA (Deceased)",
        year: "2007-2008",
      },
      {
        name: "Richard Uchechukwu Uche, (Chief, Dr.), PhD, FCA",
        year: "2008-2009",
      },
      {
        name: "Elizabeth Omeresan Adegite, (Chief, Mrs), B.Sc., MBA, FCA",
        year: "2009-2010",
      },
      {
        name: "Sebastian Achulike Owuama (Major General) (rtd), B.Sc, FCA",
        year: "2010-2011",
      },
      {
        name: "Francis Ojaide (Professor) MSc., PhD, OON, FCA",
        year: "2011-2012",
      },
      {
        name: "Doyin Owolabi, B.Sc. (Econs.), MILR, FNIM, FCA",
        year: "2012-2013",
      },
      {
        name: "Alhaji Kabir Alkali Mohammed, mni, FCIS, CGMA, FCA",
        year: "2013-2014",
      },
      {
        name: "Mr. Chidi Onyeukwu Ajaegbu, FCS, MBF, Dip. (Polygraph), FCA",
        year: "2014-2015",
      },
      {
        name: "Otunba Samuel Olufemi Deru, FNIM, JP, FCA",
        year: "2015-2016",
      },
      {
        name: "Deacon Titus Alao Soetan",
        year: "2016-2017",
      },
      {
        name: "Isma`ila Muhammadu ZAKARI (Mallam), mni, FBR, FCA (IPP*)",
        year: "2017-2018",
      },
      {
        name: "Razak JAIYEOLA (Alhaji), BSc, ACFE, CRISC, FCA (IPP*)",
        year: "2018-2019",
      },
      {
        name: "MAZI NNAMDI OKWUADIGBO FCA",
        year: "2019-2020",
      },
      {
        name: "ONOME JOY ADEWUYI (Dame) BSc, MSc, FCIB, FCA (IPP)",
        year: "2020-2021",
      },
    ],
  },
  presidency: [
    {
      name: "Davidson Chizuoke. S. Alaribe (Chief) MA,CFA, MNIM, FCA",
      position: "PRESIDENT",
      imageURL: "assets/images/presidency/president.jpg",
    },
    {
      name: "Haruna Yahaya (Mallam) mni, BSc, MBA, ANIM, FCA",
      position: "VICE-PRESIDENT",
      imageURL: "assets/images/presidency/vice-president.jpg",
    },
    {
      name: "Queensley Sofuratu Seghosime, mni, FCA,",
      position: "1ST DEPUTY VICE-PRESIDENT",
      imageURL:
        "assets/images/presidency/first-deputy-vice.jpg",
    },
    {
      name: "Etofolam F. Osuji (Dr) mni, MSc, FCA",
      position: "2ND DEPUTY VICE-PRESIDENT",
      imageURL: "assets/images/presidency/second-deputy-vice.jpg",
    },
    {
      name: "Innocent Iweka Okwuosa PhD, CPFA, FCIB, FCA",
      position: "IMMEDIATE PAST PRESIDENT",
      imageURL:
        "assets/images/presidency/immidiate-past-president.jpg",
    },

    {
      name: "Oluseyi Olanrewaju MBA, PhD, FCA",
      position: "HONOURARY TREASURER",
      imageURL:
        "assets/images/presidency/honorary-treasurer.jpg",
    },
    {
      name: "Musibau Lanre Olasunkanmi, Phd, FCA",
      position: "REGISTRAR/CHIEF EXECUTIVE",
      imageURL: "assets/images/presidency/registra.png",
    },
  ],
  councilMembers: [
    // {
    //   name: "MRS. ONOME JOY ADEWUYI, FCA",
    //   position: "",
    //   imageURL: "https://i.ibb.co/Bsmmsmn/vice.jpg"
    // },
    // {
    //   name: "Alhaja Mrs. Queensly Seghosime FCA",
    //   position: "",
    //   imageURL: "https://i.ibb.co/q5QPTtF/Alhaja-Mrs-Queensly-Seghosime-FCA.jpg"
    // },
    // {
    //   name: "Mr. Gaddaffi Ekhoragbon",
    //   position: "",
    //   imageURL: "https://i.ibb.co/XbbGpFQ/Mr-Gaddaffi-Ekhoragbon.jpg"
    // },
    // {
    //   name: "Mrs. Felicia Aina Bamgbose FCA",
    //   position: "",
    //   imageURL: "https://i.ibb.co/ZMX8922/Mrs-Felicia-Aina-Bamgbose-FCA.jpg"
    // },
    // {
    //   name: "Mrs. Hilda Ozoh FCA",
    //   position: "",
    //   imageURL: "https://i.ibb.co/gthSvrs/Mrs-Hilda-Ozoh-FCA-1.jpg"
    // },
  ],
  pastChairmen: [
    {
      name: "CHIEF SIR NGOZI AMALIRI FCA",
      position: "1ST CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/WBrFnRB/CHIEF-SIR-NGOZI-AMALIRI-FCA-1-ST-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "CHIEF AFAM UDOZOR FCA",
      position: "2ND CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/LP5jVgR/CHIEF-AFAM-UDOZOR-FCA-2-ND-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "CHIMEZIE JOEL KANU FCA",
      position: "3RD CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/93Ywzpq/CHIMEZIE-JOEL-KANU-FCA-3-RD-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "SIR NWICHI JOHN A., FCA",
      position: "4TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL: "",
    },
    {
      name: "ALHAJI OLATUNDE R OLAOWO- CA",
      position: "5TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/DVrLbTm/ALHAJI-OLATUNDE-R-OLAOWO-FCA-5-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "EFE ISERHIENRHIEN FCA",
      position: "6TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/n0HQd8x/EFE-ISERHIENRHIEN-FCA-6-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "ELDER ANDY EPIE SONA FCA",
      position: "7TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/fdG77kx/ELDER-ANDY-EPIE-SONA-FCA-7-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "JUDE SUNNY EGBO FCA",
      position: "8TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/6bDCXTJ/JUDE-SUNNY-EGBO-FCA-8-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "KELLY AYAMBA FCA",
      position: "9TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/rySn2DK/KELLY-AYAMBA-FCA-9-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "ELDER INNOCENT ANYAHURU FCA",
      position: "10TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/sFmq1Bd/ELDER-INNOCENT-ANYAHURU-FCA-10-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "CALLISTUS CHIBUNNA UFOMADU FCA",
      position: "11TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/CMVWRXg/CALLISTUS-CHIBUNNA-UFOMADU-FCA-11-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
    {
      name: "OZOADIBE ELVIS EMEKA ACA",
      position: "12TH CHAIRMAN, EASTERN ZONAL DISTRICTS",
      imageURL:
        "https://i.ibb.co/6vRwzG0/OZOADIBE-ELVIS-EMEKA-ACA-12-TH-CHAIRMAN-EASTERN-ZONAL-DISTRICTS.jpg",
    },
  ],
  executiveMembers: [
    {
      name: "Dr. Sylvanus A. Izebhokhae FCA",
      position: "CHAIRMAN ",
      imageURL: "assets/images/executive/chairman.png",
    },
    {
      name: "Nkiruka Okeke FCA",
      position: "VICE-CHAIRMAN",
      imageURL: "assets/images/executive/vice_chairman.png",
    },
    {
      name: "Obasi Ama Ibiam PhD, ACA",
      position: "SECRETARY",
      imageURL: "assets/images/executive/secretary.png",
    },
    {
      name: "Romanus Oshageny Abuguja HND, PGD, MSC, FCA",
      position: "TREASURER",
      imageURL: "assets/images/executive/treasurer.png",
    },
    {
      name: "Prof Luke Chukwuka Chukwu FCA",
      position: "ASSISTANT ZONAL SECRETARY",
      imageURL: "assets/images/executive/assistant_secretary.png",
    },
    {
      name: "Dr. Kingsley Sunday Oyekezie, FCA",
      position: "IMMEDIATE PAST CHAIRMAN",
      imageURL: "assets/images/executive/past_chairman.png",
    },
    // {
    //   name: "ESTHER EBERCHUKWU UMAHI FCA",
    //   position: "IMMEDIATE PAST SECRETARY",
    //   imageURL: "assets/images/executive/past-secretary.jpg",
    // },

    // {
    //   name: "Elder Sunday Omini Eloma FCA",
    //   position: "CONFERENCE ACCOUNTANT ",
    //   imageURL: "https://i.ibb.co/80g5TvN/Elder-Sunday-Omini-Eloma-FCA.jpg"
    // },
  ],
  // hotels: [
  //   {
  //     name: "AXARI HOTEL",
  //     rooms: "20",
  //     oldRate: "₦ 30,000",
  //     newRate: "₦ 12, 000",
  //     benefits: "INTERNET",
  //     location: "M/M HIGHWAY",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "TOM",
  //     phone: "0817 605 1608",
  //   },
  //   {
  //     name: "DANNIC HOTEL",
  //     rooms: "50",
  //     oldRate: "VARIOUS RATES",
  //     newRate: "₦ 12,000 / ₦ 10,000",
  //     benefits: "WITH / WITHOUT BREAKFAST",
  //     location: "OFF M/M HIGHWAY",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "NKANTA ELEAZER",
  //     phone: "0803 293 5616",
  //   },
  //   {
  //     name: "FORMULAR EXECUTIVE SUITES",
  //     rooms: "30",
  //     oldRate: "₦ 15,000 / ₦ 20,000",
  //     newRate: "₦ 8,000 / ₦ 10,000",
  //     benefits: "WITH BREAKFAST",
  //     location: "STATE HOUSING ESTATE",
  //     distance: "ABOUT 2KM",
  //     contactPerson: "INYANG",
  //     phone: "0803 415 5852",
  //   },
  //   {
  //     name: "CALABAR HARBOUR RESORT",
  //     rooms: "13",
  //     oldRate: "VARIOUS RATES",
  //     newRate:
  //       "₦ 16,500 / ₦ 20,000 / ₦ 22,000 / ₦ 26,000 / ₦ 33,000 / ₦ 54,000",
  //     benefits: "WITH BREAKFAST SWIMMING POOL; INTERNET",
  //     location: "ASARI ESO, OFF MCC ROAD",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "MV MADOKE",
  //     phone: "0817 003 0305 / 0901 503 1221",
  //   },
  //   {
  //     name: "MANGEL SUITES",
  //     rooms: "20",
  //     oldRate: "₦ 25,000 / ₦ 30,000",
  //     newRate: "₦ 8,000 / ₦ 10,000",
  //     benefits: "BUS RIDE TO & FRO CICC",
  //     location: "FED. HOUSING ESTATE",
  //     distance: "ABOUT 2KM",
  //     contactPerson: "MIKE ASHABA",
  //     phone: "0806 836 6683",
  //   },
  //   {
  //     name: "JORANNY HOTEL",
  //     rooms: "12",
  //     oldRate: "₦ 25,000",
  //     newRate: "₦ 13,000",
  //     benefits: "WITH BREAKFAST",
  //     location: "MCC BY TIMBER MARKET",
  //     distance: "ABOUT 2KM",
  //     contactPerson: "PATRICK",
  //     phone: "0706 822 7022",
  //   },
  //   {
  //     name: "CYTARO HOTEL",
  //     rooms: "18",
  //     oldRate: "VARIOUS RATES",
  //     newRate: "₦ 10,000 / ₦ 12,000 / ₦ 20,000 / ₦ 25,000",
  //     benefits: "",
  //     location: "STATE HOUSING ESTATE",
  //     distance: "ABOUT 2KM",
  //     contactPerson: "TOM",
  //     phone: "0803 721 3847",
  //   },
  //   {
  //     name: "PYRAMID HOTEL",
  //     rooms: "30",
  //     oldRate: "₦ 25,000",
  //     newRate: "₦ 10,000",
  //     benefits: "",
  //     location: "M/M HIGHWAY",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "CY",
  //     phone: "0818 885 7000",
  //   },
  //   {
  //     name: "XCAPE SUITES",
  //     rooms: "10",
  //     oldRate: "₦ 20,000 / ₦ 30,000",
  //     newRate: "₦ 8,000  / ₦ 10,000",
  //     benefits: "WITH BREAKFAST",
  //     location: "STATE HOUSING ESTATE",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "JOE",
  //     phone: "0803 595 6326",
  //   },
  //   {
  //     name: "NAKS HOTEL",
  //     rooms: "100",
  //     oldRate: "₦ 25,000 / ₦ 30,000",
  //     newRate: "₦ 10,000",
  //     benefits: "",
  //     location: "MARIAN ROAD",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "JOE",
  //     phone: "0703 299 4683 / 0803 924 3902",
  //   },
  //   {
  //     name: "ADRIANS PLACE",
  //     rooms: "6",
  //     oldRate: "₦ 12,000",
  //     newRate: "₦ 8,000",
  //     benefits: "",
  //     location: "MCC ROAD",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "UJU",
  //     phone: "0807 529 1001",
  //   },
  //   {
  //     name: "GOMAYS PLAZA SUITE",
  //     rooms: "30",
  //     oldRate: "₦ 25,000",
  //     newRate: "₦ 10,000",
  //     benefits: "WITH CONTINENTAL BREAKFAST",
  //     location: "STATE HOUSING ESTATE",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "TONY",
  //     phone: "0803 532 1145",
  //   },
  //   {
  //     name: "5-2-HO",
  //     rooms: "20",
  //     oldRate: "₦ 30,000",
  //     newRate: "₦ 13,000",
  //     benefits: "WITH BREAKFAST",
  //     location: "STATE HOUSING ESTATE",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "MIKE OGAR",
  //     phone: "0703 079 5745",
  //   },
  //   {
  //     name: "SPECS SUITES",
  //     rooms: "13",
  //     oldRate: "VARIOUS RATES",
  //     newRate: "₦ 9,000 / ₦ 7,000",
  //     benefits: "WITH  / WITHOUT BREAKFAST",
  //     location: "STATE HOUSING ESTATE",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "EMMA",
  //     phone: "0803 962 5495",
  //   },
  //   {
  //     name: "KEVES INN / SUITES",
  //     rooms: "30",
  //     oldRate: "VARIOUS RATES",
  //     newRate: "₦ 12,000 / ₦ 10,000",
  //     benefits: "WITH  / WITHOUT BREAKFAST",
  //     location: "M/M HIGHWAY",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "ABE SAMUEL",
  //     phone: "0806 939 6024",
  //   },
  //   {
  //     name: "CHANNEL VIEW",
  //     rooms: "40",
  //     oldRate: "₦ 30,000 - ₦ 60,000",
  //     newRate: "₦ 25,000",
  //     benefits: "WITH  BREAKFAST",
  //     location: "MCC ROAD",
  //     distance: "ABOUT 1KM",
  //     contactPerson: "EMMANUEL EKPENYONG",
  //     phone: "0803 730 5559",
  //   },
  //   {
  //     name: "TINAPA HOTEL",
  //     rooms: "100+",
  //     oldRate: "VARIOUS RATES",
  //     newRate: "₦ 13,000",
  //     benefits: "WITHOUT BREAKFAST",
  //     location: "TINAPA RESORT",
  //     distance: "OPPOSITE THE VENUE",
  //     contactPerson: "GBENGA",
  //     phone: "0818 508 8176",
  //   },
  //   {
  //     name: "ROYAL BIT",
  //     rooms: "20",
  //     oldRate: "VARIOUS RATES",
  //     newRate: "₦ 9,000",
  //     benefits: "",
  //     location: "BARRACKS ROAD",
  //     distance: "ABOUT 2KM",
  //     contactPerson: "DAVID",
  //     phone: "0803 918 2591",
  //   },
  // ],

  hotels: [
    {
      name: "UNIQUE LIFE CONTINENTAL HOTEL AND SUITES",
      phoneNumber: "08084289008",
      distanceFromVenue: "MINUTES DRIVE",
      address: "OFF ONYIRIUBI CLOSE SECRETARIAT RD, UMUAHIA ABIA STATE",
      rooms: [
        { type: "Standard Room", rate: 35000, flatRate: 35000, availableRooms: 10 },
        { type: "Deluxe Room", rate: 40000, flatRate: 40000, availableRooms: 36 },
        { type: "Executive Room", rate: 60000, flatRate: 60000, availableRooms: 4 },
        { type: "Suite Room", rate: 70000, flatRate: 70000, availableRooms: 3 },
      ],
    },
    {
      name: "RAPHA HOTEL LTD",
      phoneNumber: "07033707009 / WhatsApp: 07086216659",
      distanceFromVenue: "2 MINUTES DRIVE",
      address: "NO. 1 RAPHA AVENUE BY SECRETARIAT RD, UMUAHIA",
      rooms: [
        { type: "Super Studio Room", rate: 25000, flatRate: 25000, availableRooms: "LIMITED" },
        { type: "Exclusive Room", rate: 30000, flatRate: 30000, availableRooms: "LIMITED" },
        { type: "Classic Room", rate: 34000, flatRate: 34000, availableRooms: "LIMITED" },
        { type: "Premier Room", rate: 37000, flatRate: 37000, availableRooms: "LIMITED" },
        { type: "Luxury Room", rate: 41000, flatRate: 41000, availableRooms: "LIMITED" },
        { type: "Family Room", rate: 45000, flatRate: 45000, availableRooms: "LIMITED" },
        { type: "Ambassador Room", rate: 50000, flatRate: 50000, availableRooms: "LIMITED" },
        { type: "Presidential Room", rate: 150000, flatRate: 150000, availableRooms: "LIMITED" },
      ],
    },
    {
      name: "CANDID HOTEL & SUITES",
      phoneNumber: "07046309068",
      distanceFromVenue: "1 MINUTES DRIVE",
      address: "Secretariat by CBN Road, Umuahia, Abia State",
      rooms: [
        { type: "Standard Room", rate: 7000, flatRate: 7000, availableRooms: 5 },
        { type: "Executive Room", rate: 8000, flatRate: 8000, availableRooms: 7 },
        { type: "Super Executive", rate: 9000, flatRate: 9000, availableRooms: 4 },
        { type: "Deluxe Room", rate: 10000, flatRate: 10000, availableRooms: 2 },
        { type: "Royal Suite", rate: 12000, flatRate: 12000, availableRooms: 2 },
        { type: "Ministerial Suite", rate: 15000, flatRate: 15000, availableRooms: 4 },
      ],
    },
    {
      name: "STARBEST GLOBAL HOTEL & SUITES",
      phoneNumber: "08084529666",
      distanceFromVenue: "MINUTES DRIVE",
      address: "OGURUBE LAYOUT, OFF SECRETARIAT ROAD, UMUAHIA ABIA STATE",
      rooms: [
        { type: "Standard Room", rate: 18000, flatRate: 18000, availableRooms: 10 },
        { type: "Deluxe Room", rate: 22000, flatRate: 22000, availableRooms: 10 },
        { type: "Executive Room", rate: 25000, flatRate: 25000, availableRooms: 6 },
        { type: "Suite", rate: 40000, flatRate: 40000, availableRooms: 2 },
      ],
    },
    {
      name: "GRANDSLAM ROYAL AND SUITES",
      phoneNumber: "08084289008",
      distanceFromVenue: "2 MINUTES DRIVE",
      address: "LAYOUT, OFF IKOT EKPENE BY WINNERS ROAD UMUAHIA",
      rooms: [
        { type: "Executive Room", rate: 20000, flatRate: 20000, availableRooms: 14 },
        { type: "Grand Suites", rate: 25000, flatRate: 25000, availableRooms: 5 },
        { type: "Royal Suite", rate: 30000, flatRate: 30000, availableRooms: 7 },
        { type: "Apartment", rate: 40000, flatRate: 40000, availableRooms: 6 },
      ],
    },
    {
      name: "GOLDEN OLIVE RESORT",
      phoneNumber: "07086802894",
      distanceFromVenue: "MINUTES DRIVE",
      address: "PLOT 159 IGURUBE LAYOUT, OFF IKOT EKPENE BY WINNERS ROAD UMUAHIA",
      rooms: [
        { type: "Golden Deluxe", rate: 25000, flatRate: 25000, availableRooms: 18 },
        { type: "Golden Royale", rate: 30000, flatRate: 30000, availableRooms: 12 },
        { type: "Golden Family Royale", rate: 35000, flatRate: 35000, availableRooms: 5 },
        { type: "Executive", rate: 55000, flatRate: 55000, availableRooms: 1 },
        { type: "Diplomatic", rate: 80000, flatRate: 80000, availableRooms: 1 },
        { type: "Presidential", rate: 90000, flatRate: 90000, availableRooms: 1 },
        { type: "Kings Suite", rate: 100000, flatRate: 100000, availableRooms: 1 },
      ],
    },
    {
      name: "C5 EXCLUSIVE RESORT",
      phoneNumber: "07033320919 / 0915498340",
      distanceFromVenue: "3 MINUTES DRIVE",
      address: "NO. 1 DOZIE CLOSE BY DOZIE WAY, UMUAHIA ABIA STATE",
      rooms: [
        { type: "Simple Comfort", rate: 25000, flatRate: 25000, availableRooms: 3 },
        { type: "Executive Comfort", rate: 30000, flatRate: 30000, availableRooms: 6 },
        { type: "Exclusive Suite", rate: 35000, flatRate: 35000, availableRooms: 16 },
        { type: "Executive Suite", rate: 60000, flatRate: 60000, availableRooms: 1 },
      ],
    },
    {
      name: "ICE GRAND HOTELS",
      phoneNumber: "08084289008",
      distanceFromVenue: "2.5 MINUTES DRIVE",
      address: "NO. 2 DOZIE WAY OFF IKOT EKPENE ROAD, UMUAHIA, ABIA STATE",
      rooms: [
        { type: "Standard Room", rate: 35000, flatRate: 35000, availableRooms: 4 },
        { type: "Executive Room", rate: 45000, flatRate: 45000, availableRooms: 14 },
        { type: "Super Executive", rate: 50000, flatRate: 50000, availableRooms: 6 },
        { type: "Pro Max", rate: 65000, flatRate: 65000, availableRooms: 8 },
        { type: "Diplomatic", rate: 95000, flatRate: 95000, availableRooms: 2 },
      ],
    },
    {
      name: "PETMOBIL HOTEL AND SUITES",
      phoneNumber: "08038992625",
      distanceFromVenue: "2 MINUTES DRIVE",
      address: "NO. 10 WINNERS WAY, OFF IKOT EKPENE ROAD, UMUAHIA ABIA STATE",
      rooms: [
        { type: "Executive", rate: 10900, flatRate: 10900, availableRooms: 5 },
        { type: "Royal", rate: 12900, flatRate: 12900, availableRooms: 12 },
        { type: "Presidential", rate: 16000, flatRate: 16000, availableRooms: 2 },
      ],
    },
  ],

  districtSocieties: [
    "-NAME OF DISTRICT SOCIETY-",
    "ABA & DISTRICT SOCIETY",
    "ABAKALIKI & DISTRICT SOCIETY",
    "ABEOKUTA & DISTRICT SOCIETY",
    "ABUJA & DISTRICT SOCIETY",
    "ABRAKA & DISTRICT SOCIETY",
    "ADO-EKITI & DISTRICT SOCIETY",
    "AFIKPO & DISTRICT SOCIETY",
    "AGBANI & DISTRICT SOCIETY",
    "AKOKO & DISTRICT SOCIETY",
    "AKURE & DISTRICT SOCIETY",
    "ALIMOSHO & DISTRICT SOCIETY",
    "AMUWO & DISTRICT SOCIETY",
    "ASABA & DISTRICT SOCIETY",
    "AUCHI & DISTRICT SOCIETY",
    "AWKA & DISTRICT SOCIETY",
    "BAUCHI & DISTRICT SOCIETY",
    "BENIN & DISTRICT SOCIETY",
    "BONNY KINGDOM & DISTRICT SOCIETY",
    "CALABAR & DISTRICT SOCIETY",
    "CAMEROUN & DISTRICT SOCIETY",
    "CANADA & DISTRICT SOCIETY",
    "DALA & DISTRICT SOCIETY",
    "DUTSE & DISTRICT SOCIETY",
    "EKET & DISTRICT SOCIETY",
    "EKPOMA & DISTRICT SOCIETY",
    "ENUGU & DISTRICT SOCIETY",
    "EPE & DISTRICT SOCIETY",
    "GWAGWALADA & DISTRICT SOCIETY",
    "GOMBE & DISTRICT SOCIETY",
    "IBADAN & DISTRICT SOCIETY",
    "IFE & DISTRICT SOCIETY",
    "IJEBU-ODE & DISTRICT SOCIETY",
    "IKEJA & DISTRICT SOCIETY",
    "IKOM OGOJA & DISTRICT SOCIETY",
    "IKORODU & DISTRICT SOCIETY",
    "IKOT EKPENE & DISTRICT SOCIETY",
    "IKWERRE & DISTRICT SOCIETY",
    "IKWUANO & DISTRICT SOCIETY",
    "ILARO & DISTRICT SOCIETY",
    "ILESA & DISTRICT SOCIETY",
    "ILORIN & DISTRICT SOCIETY",
    "ILUPEJU/GBAGADA & DISTRICT SOCIETY",
    "IRELAND & DISTRICT SOCIETY",
    "JALINGO & DISTRICT SOCIETY",
    "JOS & DISTRICT SOCIETY",
    "KADUNA & DISTRICT SOCIETY",
    "KANO & DISTRICT SOCIETY",
    "KATSINA & DISTRICT SOCIETY",
    "LAFIA & DISTRICT SOCIETY",
    "LAGELU & DISTRICT SOCIETY",
    "LAGOS & DISTRICT SOCIETY",
    "LAGOS MAINLAND & DISTRICT SOCIETY",
    "LEKKI & DISTRICT SOCIETY",
    "LOKOJA & DISTRICT SOCIETY",
    "MAIDUGURI/DAMATURU & DISTRICT SOCIETY",
    "MAKURDI & DISTRICT SOCIETY",
    "MALAYSIA & DISTRICT",
    "MINNA & DISTRICT SOCIETY",
    "MOWE & DISTRICT SOCIETY",
    "NEKEDE & DISTRICT SOCIETY",
    "NSUKKA & DISTRICT SOCIETY",
    "NYANYA-MARARABA & DISTRICT SOCIETY",
    "OBIO AKPOR & DISTRICT SOCIETY",
    "OFFA & DISTRICT SOCIETY",
    "OGBOMOSO & DISTRICT SOCIETY",
    "OJO, BADAGRY, AGBARA (O.B.A) & DISTRICT SOCIETY",
    "OLUYOLE & DISTRICT SOCIETY",
    "ONITSHA & DISTRICT SOCIETY",
    "OSOGBO & DISTRICT SOCIETY",
    "OTA & DISTRICT SOCIETY",
    "OWERRI & DISTRICT SOCIETY",
    "OWO-OSE & DISTRICT SOCIETY",
    "OYO & DISTRICT SOCIETY",
    "PORT HARCOURT & DISTRICT SOCIETY",
    "SOKOTO & DISTRICT SOCIETY",
    "SOUTH AFRICA & DISTRICT SOCIETY",
    "SURULERE & DISTRICT SOCIETY",
    "UMUAHIA & DISTRICT SOCIETY",
    "UNITED KINGDOM & DISTRICT",
    "USA & DISTRICT SOCIETY",
    "UYO & DISTRICT SOCIETY",
    "WARRI & DISTRICT SOCIETY",
    "YOLA & DISTRICT SOCIETY",
    "YENAGOA & DISTRICT SOCIETY",
    "ZARIA & DISTRICT SOCIETY",
    "CBN CHAPTER",
    "LAGOS STATE PUBLIC SERVICE CHAPTER",
    "FIDELITY BANK PLC CHAPTER",
    "FIRST BANK CHAPTER",
    "SOCIETY OF WOMEN ACCOUNTANTS OF NIGERIA (SWAN)",
    "OFFICE OF THE AUDITOR-GENERAL FOR THE FEDERATION (OAuGF) CHAPTER",
    "OFFICE OF THE ACCOUNTANT-GENERAL OF THE FEDERATION (OAGF) CHAPTER",
    "OGUN STATE PUBLIC SERVICE CHAPTER",
    "NNPC CHAPTER",
    "TCN CHAPTER",
    "RCCG CHAPTER",
    "NIA CHAPTER",
    "FIRS CHAPTER"
  ],



};



